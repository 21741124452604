body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    min-height: 1200px;
    // background: linear-gradient(to bottom, #ccc, #6cd09f); /* Dark green to lighter green */
  }

  .home-page-wrapper {
    display: flex;
    flex-direction: column;
  }

  .home-page-card-wrapper {
    justify-content: space-around;
    width: auto;
    display: flex;
    flex-wrap: wrap;
  }
