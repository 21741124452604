@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes bump {
    0%, 100% {
      transform: translate(0, 0); /* Start and end at the original position */
    }
    50% {
      transform: translate(-2px, -4px); /* Move up by 4px and left by 2px at the halfway point */
    }
  }