/* CardComponent.css */
.card-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }
  
  .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 400px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .card h2 {
    margin-top: 0;
  }
  
  .card button {
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .card button:hover {
    background-color: #A0522D; /* Using warm brown for hover effect */
  }
  